<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="onFailedAuthentication")
    ClientHeader(:content="headerContent")
    div.wrap-client-schedule
      ModuleSchedule(
        v-if="clientId"
        :cliendId="clientId"
        @openModal="() => { showModalWindow = true }")
    ClientFooter
    ModalWindow(
      @closeModal="closeModalWindow"
      modalContentId="addScheduleEvent"
      :showModal="showModalWindow"
      :option="{ clientId: clientId }")
</template>

<style lang="scss" scoped>
.wrap-client-schedule {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import db from '@/components/utils/firebase'
import Auth from '@/components/auth'
import ClientHeader from '@/components/shared/ClientHeader'
import ClientFooter from '@/components/shared/ClientFooter'
import ModalWindow from '@/components/shared/Modal'
import ModuleSchedule from '@/components/module/ModuleSchedule'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ClientHeader,
    ClientFooter,
    ModalWindow,
    ModuleSchedule
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      headerContent: {
        label: 'カレンダー'
      },
      showModalWindow: false,
      clientId: null
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    closeModalWindow () {
      this.showModalWindow = false
    },
    async onLoggedIn () {
      this.clientId = await db.collection('CLIENTS')
        .where('authedClientUid', '==', this.uid)
        .get()
        .then(q => {
          return q.docs[0].id
        })
    }
  }
}
</script>
